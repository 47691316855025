import getOffset from "../utils/getOffset";
import { sanitize } from "dompurify";

function getUrlVars() {
    var vars = [],
        hashes = [],
        hash;
    var url = new URL(window.location.href);
    var urlParams = url.searchParams;

    urlParams.forEach((value, key) => {
        hashes.push(`${key}=${sanitize(value)}`);
    });
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

class TakeQuiz {
    constructor() {
        this.init();
    }
    init() {
        var form = $("#eval-questions"),
            length = $(".item", form).length,
            prev = $(".prev"),
            next = $(".next"),
            finish = $(".finish");

        finish
            .on("click", function() {
                $(".pre-eval").addClass("hidden");
                $(".post-eval").removeClass("hidden");
            })
            .addClass("invisible");

        form.on("slide.bs.carousel", function(e) {
            var activeQuestion = $(e.relatedTarget),
                index = activeQuestion.index();
            index === 0 ? prev.addClass("invisible") : prev.removeClass("invisible");
            if (index + 1 === length) {
                next.addClass("hidden");
                finish.removeClass("hidden");
            } else {
                finish.addClass("hidden");
                next.removeClass("hidden");
            }
            activeQuestion.find(":checked").length !== 0 ? next.removeClass("invisible") : next.addClass("invisible");
        });

        $(".btn-link").on("click", function() {
            let activeQuestion = $(".item", form).filter(".active");
            let index = activeQuestion.index();

            const yawa = $(".controls-wrap--desktop .carousel-indicators li");

            $(this).hasClass("prev") ? index-- : index++;

            yawa.removeClass("current");
            yawa.eq(index).addClass("current");
        });

        $(".item", form).on("change", function() {
            var activeQuestion = $(this),
                index = activeQuestion.index();
            let contexts = [$(".controls-wrap--mobile"), $(".controls-wrap--desktop")];
            if (activeQuestion.find(":checked").length !== 0) {
                index !== length - 1 ? next.removeClass("invisible") : finish.removeClass("invisible");
                contexts.forEach(function(context) {
                    $(".carousel-indicators li", context)
                        .eq(index)
                        .addClass("answered");
                });
                $("#eval-questions .item")
                    .eq(index)
                    .addClass("answered");
            } else {
                next.addClass("invisible");
                contexts.forEach(function(context) {
                    $(".carousel-indicators li", context)
                        .eq(index)
                        .removeClass("answered");
                });
                $("#eval-questions .item")
                    .eq(index)
                    .removeClass("answered");
            }
        });

        form.on("slide.bs.carousel", function() {
            const targetScroll = document.querySelector("#eval-questions .item.active");
            if (targetScroll) {
                window.scrollTo({
                    top: getOffset(targetScroll),
                    behavior: "smooth"
                });
            }
        });

        $(window).on("pageshow", function() {
            if (form.length) {
                form[0].reset();
                var scrollDist = form.offset().top - $("header").height();
                if (form.offset().top > $(window).height()) scrollDist = form.offset().top;
                if (getUrlVars()["answer"]) {
                    $("html, body").animate(
                        {
                            scrollTop: scrollDist
                        },
                        function() {
                            $('[name="uncontrolled-symptoms"][value="' + getUrlVars()["answer"] + '"]').trigger("click");
                            next.trigger("click");
                        }
                    );
                }
            }
        });
    }
}

export default TakeQuiz;
