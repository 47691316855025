/* -------------------
BEGIN image-comparison-slider
Sitecore Resource: afe92ab8-81fe-419f-b0db-65c2b910cbba
-------------------*/

class ImageComparisonSlider {
    constructor() {
        this.activeMover = null;
        this.mouseDownX = 0;
        this.X = 0;
        this.init();
        this.cta = null;
    }

    handleStart(e) {
        const $this = this;
        if (!e.target.classList.contains("mover")) return;

        $this.activeMover = e.target;
        $this.mouseDownX = 1;
        $this.cta = $this.activeMover.querySelector(".mover-cta");

        switch (e.type) {
            case "mousedown":
                $this.X = e.clientX;
                break;
            case "touchstart":
                $this.X = e.touches[0].clientX;
                break;
            default:
                return;
        }

        $this.cta.style.opacity = 0;
        document.getElementsByTagName("body")[0].classList.add("before-after-interacted");
    }

    handleMove(e) {
        const $this = this;
        if ($this.mouseDownX) {
            let Xvar = 0,
                moverOffset = $this.activeMover.getBoundingClientRect().width / 2;

            switch (e.type) {
                case "mousemove":
                    Xvar = e.clientX;
                    break;
                case "touchmove":
                    Xvar = e.touches[0].clientX;
                    break;
                default:
                    return;
            }

            let leftPos = parseInt($this.activeMover.style.left) + (Xvar - $this.X);

            if (leftPos + moverOffset > $this.activeMover.parentNode.offsetWidth) {
                leftPos = $this.activeMover.parentNode.offsetWidth - moverOffset;
            } else if (leftPos + moverOffset <= 0) {
                leftPos = moverOffset * -1;
            }

            $this.activeMover.style.left = leftPos + "px";
            $this.X = Xvar;

            let clipRight = moverOffset + parseInt($this.activeMover.style.left);
            $this.activeMover.nextElementSibling.style.clip = "rect(0px, " + clipRight + "px, " + $this.activeMover.getBoundingClientRect().height + "px, 0px)";
        }
    }

    handleStop() {
        const $this = this;
        $this.mouseDownX = 0;
    }

    handleResize() {
        let movers = document.querySelectorAll(".image-splitter .mover"),
            length = movers.length;

        while (length--) {
            let moverWidth = movers[length].getBoundingClientRect().width;
            let imgLeft = movers[length].nextElementSibling;
            let width = imgLeft.getBoundingClientRect().width;
            let height = 9999;
            let position = parseInt(movers[length].dataset["initialSliderPosition"]) * 0.01;

            movers[length].style.left = width * position - moverWidth / 2 + "px";
            imgLeft.style.clip = "rect(0, " + width * position + "px, " + height + "px, 0px)";
        }
    }

    init() {
        const $this = this;

        document.addEventListener("mousedown", e => {
            $this.handleStart(e);
        });
        document.addEventListener("touchstart", e => {
            $this.handleStart(e);
        });

        document.addEventListener("mousemove", e => {
            $this.handleMove(e);
        });
        document.addEventListener("touchmove", e => {
            $this.handleMove(e);
        });

        document.addEventListener("mouseup", () => {
            $this.handleStop();
        });
        document.addEventListener("touchend", () => {
            $this.handleStop();
        });

        window.addEventListener("resize", () => {
            $this.handleResize();
        });
        window.addEventListener("load", () => {
            $this.handleResize();
        });
    }
}

export default ImageComparisonSlider;
