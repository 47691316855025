import stripTrailingSlash from "../utils/stripTrailingSlash";
/* -------------------
BEGIN core-header
Sitecore Resource: a7ad17f9-2618-44ea-9250-8f191d1fc054
   -------------------*/
const coreHeader = function() {
    var $body = $("body"),
        currentLocation = location.pathname.length > 1 ? stripTrailingSlash(location.pathname) : location.pathname;

    function adjustHeaderBottomVariable() {
        //modify css variable so mobile nav can extend to bottom of viewport
        var header = document.querySelector("header");
        header.style.setProperty("--mobile-nav-height", window.innerHeight - header.getBoundingClientRect().bottom + "px");
    }

    //fix for score nav menu toggle
    $(".navbar-toggle").addClass("collapsed");

    //add active state to nav
    $('.nav a[href="' + decodeURI(currentLocation) + '"]')
        .parents("li")
        .each(function() {
            $(this).addClass("active");
        });

    //open active subnav
    $(".score-nav li.dropdown.active").addClass("open");

    $(".score-nav > .navbar-collapse")
        .on("hidden.bs.collapse", function() {
            $body.removeClass("nav-open");

            $(".score-nav li.dropdown").removeClass("open");
            $(".score-nav li.dropdown.active").addClass("open");
        })
        .on("show.bs.collapse", function() {
            $body.addClass("nav-open");

            adjustHeaderBottomVariable();
        });

    //fix iPad hover dropdown
    $("li.dropdown").on("touchstart", function(e) {
        if ($(window).width() > 991) {
            e.stopPropagation();
            if (!$(this).hasClass("hover")) {
                e.preventDefault();
                $("li.dropdown.hover").removeClass("hover");
                $(this).addClass("hover");
            }
        }
    });

    //fix utility link dropdown for keyboard accessibility
    $("li.dropdown").on("keyup", function(e) {
        e.preventDefault();
        let $this = $(this);
        let keycode = e.key;
        if (keycode == "Enter") {
            e.stopPropagation();
            if (!$this.hasClass("hover")) {
                e.preventDefault();
                $this.addClass("hover");
            }
        }
        if (keycode == "Escape") {
            e.stopPropagation();
            if ($this.hasClass("hover")) {
                e.preventDefault();
                $this
                    .removeClass("hover")
                    .removeClass("open")
                    .find("a.toggle-caret")
                    .attr("aria-expanded", "true");
                if ($this.hasClass("score-megamenu-dropdown")) {
                    let aList = $this.find("a").first();
                    aList.trigger("focus");
                } else {
                    $this.trigger("focus");
                }
                $this.find("a.toggle-caret").attr("aria-expanded", "false");
            }
        }
    });

    //choose condition navigation for keyboard accessibility menu parent
    $(".select-condition-dropdown, .dropdown.style-inidication").on("keyup", function(e) {
        e.preventDefault();
        let keycode = e.key;
        if (keycode == "Enter") {
            $(".indication-dropdown, .dropdown.style-inidication")
                .find("ul")
                .toggleClass("show");
            $(this).attr("aria-expanded", "true");
        }
        if (keycode == "Escape") {
            $(".indication-dropdown, .dropdown.style-inidication")
                .removeClass("open")
                .find("ul")
                .removeClass("show");
            $(this).attr("aria-expanded", "false");
        }
    });

    //choose condition navigation for keyboard accessibility menu children
    $(".indication-dropdown ul li, .dropdown.style-inidication").on("keyup", function(e) {
        e.preventDefault();
        let keycode = e.key;
        if (keycode == "Escape") {
            $(".indication-dropdown, .dropdown.style-inidication")
                .find("ul")
                .removeClass("show");
            $(".select-condition-dropdown, .dropdown.style-inidication").trigger("focus");
        }
    });

    //close dropdowns when tapping outside them
    $body.on("touchstart", function() {
        $(".dropdown.hover").removeClass("hover");
        $(".dropdown.open").removeClass("open");
    });

    //close dropdowns for keyboard accessibility
    $body.on("keyup", function(e) {
        e.preventDefault();
        let keycode = e.key;
        if (keycode == "Escape") {
            $(".dropdown.hover").removeClass("hover");
            $(".dropdown.open").removeClass("open");
            $("li.dropdown").trigger("focus");
        }
    });

    //prevent propagation when tapping isde PI dropdown
    $(".dropdown").on("touchstart", function(e) {
        e.stopPropagation();
    });

    //toggle dropdowns based on A tag clicks
    $(".navbar-default.score-megamenu .navbar-nav > li.dropdown > a:not(.toggle-caret)").on("click", function(e) {
        e.preventDefault();

        //close any other open navigation dropdowns
        $(".navbar-default.score-megamenu .navbar-nav > li.dropdown")
            .not($(this).parent())
            .removeClass("open");

        if ($(this).attr("data-toggle")) return;

        $(this)
            .parent()
            .toggleClass("open");
    });

    $("header .dropdown").on("shown.bs.dropdown", adjustHeaderBottomVariable);

    // dynamically set the dropdown padding top to accomodate the menu white space behind the primary dropdown link
    let mqLG = window.matchMedia("(min-width: 1200px)");
    let mqMD = window.matchMedia("(min-width: 992px)");

    function handleMediaQuery() {
        if (document.querySelector(".mgnlAdmin")) return;

        if (mqMD.matches) {
            $(".dynamic-header .navbar-default.score-megamenu .navbar-nav > li.dropdown > a:not(.toggle-caret)").each(function() {
                var height = $(this).outerHeight();
                $(this)
                    .siblings(".dropdown-menu")
                    .css("padding-top", height + 15);
            });
        } else {
            $(".dynamic-header .navbar-default.score-megamenu .navbar-nav > li.dropdown > .dropdown-menu").css("padding-top", "");
        }
    }

    mqLG.onchange = handleMediaQuery;
    mqMD.onchange = handleMediaQuery;

    handleMediaQuery();
};

export default coreHeader;
